import { ref, watch, computed } from '@vue/composition-api'
import router from '@/router'
import axios from '@axios'
import store from '@/store'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
const { i18n } = useI18nUtils();
function i18nT(str){
	return i18n(str)
};

export default function useTrashList(){
	const refTrashListTable = ref(null);

	const tableColumns = [
		{key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
		{key: 'TrashLabel', label: i18nT(`Titre`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1', thStyle: { width: '90%' } },
		// {key: 'TrashType', label: i18nT(`Type`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1', thStyle: { width: '20%' } },
		{key: 'TrashCreatedAt', label: i18nT(`Date`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1', thStyle: { width: '5%' }  },
		{
			key: 'action',
			label: i18nT(`Actions`),
			thClass: 'tbl-actions',
			tdClass: 'sticky-column',
			stickyColumn: true,
		},

	]

	const perPage = ref(10);
	const totalTrash = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];
	const searchQuery = ref('');
	const sortBy = ref('');
	const isSortDirDesc = ref(true);
	const trash = ref([]);
	const trashType = ref('');
	const trashProperty = ref('');
	const types = ref([])

	const dataMeta = computed(()=> {
		const localItemsCount = refTrashListTable.value
			? refTrashListTable.value.localItems.length
			: 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalTrash.value,
		}
	})

	let timeoutId = null;

	const refetchData = () => {
		refTrashListTable.value.refresh();
	}

	watch(
[
			currentPage,
			perPage,
			trashProperty,
			trashType,

		],
		() => {
			refetchData();
		}
	)

	watch(
		searchQuery,
		() => {
			clearTimeout(timeoutId)
			timeoutId = setTimeout(() => {
				refetchData()
			}, 500)
		}
	)

	const fetchTrash = (ctx, callback) =>{
		const params = {
			page: currentPage.value,
			show_per_page: perPage.value,
			sort_by: sortBy.value,
			sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
			search: searchQuery.value
		}
		if(trashType.value !== '' && trashType.value != '-1') {
			params['filter[ObjectType]'] = trashType.value
		}

		if(trashProperty.value !=='' && trashProperty.value != '-1') {
			params['filter[PropertyID]'] = trashProperty.value
		}

		axios.get(`landlord/trash`, {
			params,
		}).then(({data}) => {
			if(data.data.records){
				trash.value = data.data.records
				types.value = data.data.types
				callback(data.data.records)
			}else{
				trash.value = []
				types.value = []
				callback([])
			}

			totalTrash.value = data.data.pagination['records_count']
		}).catch(err => {
			store.commit('app/SET_GLOBAL_MESSAGE', {message: err.response.data['error_message'], type:'danger'} )
		})

	}

	return {
		refTrashListTable,
		tableColumns,
		perPage,
		totalTrash,
		currentPage,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		trash,
		trashType,
		trashProperty,
		dataMeta,
		types,
		fetchTrash,
		refetchData
	}

}


