<template>
    <div ref="main-container">
        <b-card no-body>
            <b-card-body>
                <div>
                    <b-row class="mb-2">
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <h1 class="mb-0">{{i18nT(`Corbeille`)}}</h1>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <b-button @click="onEmptyTrash"
                                      variant="outline-danger"
                                      class="float-right"
                            >
                                {{i18nT(`Vider la corbeille`)}}
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row v-if="isAlertVisisble">
                        <b-col
                            cols="12"        
                        >
                            <b-alert
                                class="py-1 px-1"
                                variant="primary"
                                show
                            >
                                <div class="d-flex justify-content-between">
                                    <h4 class="text-primary">{{ i18nT(`Information`)}}</h4>
                                    <span class="cursor-pointer" @click="isAlertVisisble = false">
                                        <RtIcon variant="light" icon="circle-xmark" :size="20"/>
                                    </span>
                                </div>
                                <p class="mt-50">{{ i18nT(`Vous avez jusqu'à 7 jours pour restaurer les fichiers supprimés. Après cette période, les fichiers sont supprimés définitivement`)}}.</p>
                            </b-alert>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="3">
                            <b-form-group :label="i18nT(`Type`)">
                                <v-select
                                    v-model="trashType"
                                    :options="trashTypes"
                                    :placeholder="i18nT(`Tout types`)"
                                    :clearable='false'
                                    label="text"
                                    :reduce="option => option.value"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                            <b-form-group :label="i18nT(`Biens`)">
                                <v-select
                                    v-model="trashProperty"
                                    :options="trashProperties"
                                    :placeholder="i18nT(`Tout les biens`)"
                                    :clearable='false'
                                    label="text"
                                    :reduce="option => option.value"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-card-body>
        </b-card>
        <!--                TABLE AND SEARCH-->
        <b-card no-body>
            <div>
                <b-row class="mx-2 mt-2">
                    <b-col
                        cols="12"
                        md="7"
                        sm="6"
                        class="mb-1 pl-0"
                    >
                        <v-select
                            v-model="perPage"
                            :clearable="false"
                            :options="perPageOptions"
                            class="per-page-selector d-inline-block"
                        />
                        <b-dropdown
                            v-if="selectedItems.length > 0"
                            class="d-inline ml-1"
                            size="md"
                            text="Actions"
                            variant="outline-secondary"
                        >
                            <b-dropdown-item @click="onBulkRestore()">
                                <RtIcon :size="16" variant="light" icon="arrows-rotate"/>
                                <span
                                    class="align-middle ml-50"
                                >{{
                                        i18nT(`Restaurer`)
                                    }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="onBulkDelete()">
                                <span class="text-danger"><RtIcon :size="16" variant="light" icon="trash"/></span>
                                <span
                                    class="align-middle ml-50 text-danger"
                                >{{
                                        i18nT(`Supprimer`)
                                    }}</span>
                            </b-dropdown-item>
                        </b-dropdown>

                    </b-col>
                    <!--                            search-input-->
                    <b-col
                        cols="12"
                        md="5"
                        sm="6"
                        class="mb-1 pr-0 d-flex justify-content-end"
                    >
                        <div class="custom-search d-flex justify-content-end">
                            <b-form-group>
                                <div class="input-group input-group-merge" role="group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <RtIcon variant="light" icon="search"/>
                                        </div>
                                    </div>
                                    <b-form-input
                                        v-model.lazy="searchQuery"
                                        :placeholder="i18nT(`Chercher`)"
                                        class="d-inline-block"
                                        type="text"
                                    />
                                </div>
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
                <b-table
                    ref="refTrashListTable"
                    v-model="currentItems"
                    :key="'TrashTable' + tableColumns.length"
                    :empty-html="emptyHtml"
                    :fields="tableColumns"
                    :items="fetchTrash"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="isSortDirDesc"
                    class="position-relative"
                    primary-key="id"
                    responsive
                    show-empty
                    @refreshed="onTableRefresh"
                >
                    <template v-slot:head(checkbox)="data">
                        <span>{{data.label.toUpperCase()}}</span>
                        <b-form-checkbox
                            v-model="selectAllStatus"
                            @change="onSelectAll"
                        />
                    </template>
                    <template #cell(checkbox)="data">
                        <b-form-checkbox
                            v-model="selectedItems"
                            :value="data.item.TrashID"
                            class="pl-0"
                        />
                    </template>
                    <template #cell(TrashLabel)="data">
                        <div class="text-nowrap mt-50 d-flex">
                            <span class="mr-1 ren-icon-container d-flex justify-content-center align-items-center">
                                <rt-icon :icon="getTypeIcon(data.item.TrashFromTable)" variant="light" :size="23"
                                         class="mr-1"/>
                            </span>
                            <div class="flex-column"><h5
                                class="d-inline-block mr-1 mb-0"
                            >
                                {{ data.item.TrashLabel }}
                            </h5>
                                <p class="m-0">{{ i18nT(getTrashTypeLabel(data.item.TrashFromTable)) }}</p></div>
                        </div>


                    </template>

<!--                    <template #cell(TrashType)="data">-->

<!--                        <h5>{{i18nT(getTrashTypeLabel(data.item.TrashFromTable))}}</h5>-->

<!--                    </template>-->

                    <template #cell(TrashCreatedAt)="data">
                        <h5>{{data.item.TrashCreatedAt | date}}</h5>
                    </template>

                    <template #cell(action)="data">
                        <div class="d-flex justify-content-center">
                            <b-link
                                @click="onRestore(data.item)"
                                class="text-info"
                                v-b-tooltip.hover.top="i18nT(`Restaurer`)"
                            >
                                <RtIcon variant="light" icon="arrows-rotate mr-1" :size="15"/>
                            </b-link>
                            <b-link
                                @click="onDelete(data.item)"
                                class="text-danger"
                                v-b-tooltip.hover.top="i18nT(`Supprimer`)"
                            >
                                <RtIcon variant="light" icon="trash mr-1" :size="15"/>
                            </b-link>
                        </div>
                    </template>

                </b-table>
            </div>
            <div class="mx-1 mb-2">
                <b-row v-if="trash.length > 0">
                    <b-col
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        cols="12"
                        sm="4"
                    >
                        <b-form-checkbox
                            v-model="selectAllStatus" class="d-inline-block mx-1"
                            @change="onSelectAll"
                        >
                            {{ i18nT(`Tout`)}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col  cols="12" sm="4" class="d-flex align-items-center justify-content-center">
                        <span class="text-muted ml-1 d-none d-md-flex text-nowrap">
                        {{ i18nT(`Affichage de`) }} {{ dataMeta.from }} {{ i18nT(`à`) }}
                        {{ dataMeta.to }} {{ i18nT(`sur`) }} {{ dataMeta.of }}
                        {{ i18nT(`entrées`) }}
                        </span>
                    </b-col>
                    <!--                        Pagination-->
                    <b-col
                        cols="12"
                        sm="4"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="totalTrash"
                            class="mb-0 mt-1 mt-sm-0"
                            first-number
                            last-number
                            next-class="next-item"
                            prev-class="prev-item"
                        >
                            <template #prev-text>
                                <RtIcon variant="light" icon="chevron-left" :size="16"/>
                            </template>
                            <template #next-text>
                                <RtIcon variant="light" icon="chevron-right" :size="16"/>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>

import {
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BButton,
    BFormRadioGroup,
    BRow, BFormCheckbox, BLink
} from 'bootstrap-vue'
import useTrashList from "@/views/landlord/Trash/tableTrashList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from 'vue-select'
import store from '@/store/index'


export default{
    components:{
        BLink,
        BFormCheckbox,
        BFormRadioGroup,
        BDropdownItem,
        BDropdown,
        BButton,
        BFormGroup,
        BCol,
        BRow,
        vSelect,
        BCard,
    },
    data(){
        return {
            isAlertVisisble: true,
            currentItems: [],
            selectedItems: [],
            selectAllStatus: false,
            trashTypes: [],
            trashProperties:[],
            icons: [
                {trashType: 'Tenants', icon: "user-group"},
                {trashType: 'Properties', icon: 'door-open'},
                {trashType: 'Buildings', icon: 'building'},
                {trashType: 'LeaseHandovers', icon: 'clipboard-list'},
                {trashType: 'Reservations', icon: 'calendar-week'},
                {trashType: 'Messages', icon: 'message'},
                {trashType: 'Documents', icon: 'file-text'},
                {trashType: 'Tasks', icon: 'check-square'},
                {trashType: 'Payments', icon: 'credit-card'},
                {trashType: 'Notes', icon: 'notes'},
                {trashType: 'Contacts', icon: 'address-book'},
                {trashType: 'Leases', icon: 'key'},
                {trashType: 'TenantsCandidatures', icon: 'user-tie'},
                {trashType: 'Equipments', icon: 'couch'},
            ],
    }
    },
    setup(){
        const {
            refetchData,
            fetchTrash,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTrashListTable,
            trash,
            types,
            totalTrash,
            trashType,
            trashProperty,
        } = useTrashList();

        return {
            refetchData,
            fetchTrash,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTrashListTable,
            totalTrash,
            trashType,
            trashProperty,
            trash,
            types
        }
    },
    created(){
        this.$http.get(`landlord/trash/filters`).then(({data})=>{
            this.trashTypes = data.data.types.map(type=>({text: this.i18nT(type.label), value: type.id}));
            this.trashTypes.unshift({text: this.i18nT(`Tout types`), value: '-1'});
            this.trashProperties = data.data.properties.map(property=>({text: this.i18nT(property.label), value: property.id}));
            this.trashProperties.unshift({text: this.i18nT(`Tout les biens`), value: '-1'});
        }).catch(err=> console.log(err))
    },
    methods:{
        getTypeIcon(type){
            return this.icons.find(i=>i.trashType === type).icon
        },
        getTrashTypeLabel(type){
            return this.types.find(t=>t.id === type).label
        },
        onDelete(item) {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    console.log(result.value)
                    this.$http.post(`landlord/trash/deleteFinal?id=${item.TrashID}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Supprimé avec succès`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.refetchData()
                        }).catch(err => {
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })

        },
        onBulkDelete(){
            //TODO
            const idsString = this.selectedItems.join(',')

            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    console.log(result.value)
                    this.$http.post(`landlord/trash/massDelete?ids=${idsString}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Supprimé avec succès`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false;
                            this.refetchData()
                        }).catch(err => {
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.selectedItems = []
                        this.selectAllStatus = false;
                        this.refetchData()
                    })
                }
            })

        },
        onRestore(item){
            this.$http.post(`landlord/trash/restore?id=${item.TrashID}`).then(()=>{
                //TODO - change lang
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Restauré avec succès`),
                        icon: 'CheckIcon',
                        variant: 'success',
                    }
                })
                this.refetchData();
            }).catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    }
                })
                this.refetchData();
            })
        },
        onBulkRestore() {
            // TODO
            const idsString= this.selectedItems.join(',')

            this.$http.post(`landlord/trash/massRestore?ids=${idsString}`).then(()=>{
                //TODO - change lang
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Restauré avec succès`),
                        icon: 'CheckIcon',
                        variant: 'success',
                    }
                })
                this.selectAllStatus = false;
                this.selectedItems = [];
                this.refetchData();
            }).catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    }
                })
                this.selectAllStatus = false;
                this.selectedItems = [];
                this.refetchData();
            })

        },
        onTableRefresh(){
            this.selectedItems=[]
        },
        onSelectAll(val){
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.TrashID)
            } else {
                this.selectedItems = []
            }
        },
        onEmptyTrash(){
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    console.log(result.value)
                    this.$http.post(`landlord/trash/empty`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Supprimé avec succès!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.refetchData()
                        }).catch(err => {
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        }
    },
    computed: {
        emptyHtml() {
            let imgPath = store.state.appConfig.layout.skin === 'dark'

            return "<img src='" + require(imgPath ? '@client/images/errorDark.svg' : '@client/images/error.svg')
                + "' alt='Error page' class='img-fluid' width='300'><br/><h2>"
                + this.i18nT(`Il n'y a rien par ici...`) + "</h2><p>"
                + this.i18nT(`Cette page permet de gérer les éléments supprimés.`) + "</p>"
        },
    }
}

</script>